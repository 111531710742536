@import "../App.scss";

.page {
  .items {
    width: 50%;
    height: 100%;
    box-shadow: 0 0.5rem 1rem rgba($black, 0.1);
  }

  .active-item {
    width: 50%;
    background-color: $gray-15;
    @include flex(column, flex-start, flex-start);
    height: 100%;
    overflow-y: auto;

    .active-item-container {
      width: 100%;
      @include flex(column, flex-start, flex-start);

      .active-item-hero {
        height: 30vh;
        width: 100%;
        position: relative;
        background-color: $gray-11;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        svg {
          position: absolute;
          top: 1rem;
          right: 1rem;
          width: 8rem;
          height: 8rem;
          color: $white;
          cursor: pointer;
        }

        h4 {
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          color: $white;
          font-size: 3rem;
          font-weight: 500;
        }
      }

      form {
        width: 100%;
        @include flex(column, flex-start, flex-start);
        z-index: 2;

        .form-row {
          width: 100%;
          @include flex(row, space-between, flex-start);
          background-color: $gray-15;
          border-bottom: 1px solid $gray-13;

          .form-row-images {
            @include flex(row, flex-start, flex-start);
            width: 100%;
            flex-wrap: wrap;

            .form-row-image {
              width: 50%;
              height: 12rem;
              background-color: $gray-14;
              border: 0.12rem solid $gray-12;
              border-top: 0.12rem solid $gray-13;
              border-left: 0.12rem solid $gray-13;
              position: relative;

              .delete-image-btn {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                background-color: $gray-13;
                color: $gray-5;
                font-weight: 600;
                border: 0.1rem solid $gray-11;
                border-top: 0.1rem solid $gray-12;
                border-left: 0.1rem solid $gray-12;
                font-size: 0.8rem;
                height: 1.7rem;
                width: 1.7rem;
                @include flex(row, center, center);
                &:hover {
                  background-color: $gray-14;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          h5 {
            font-size: 1rem;
            font-weight: 600;
            color: $black;
            width: 100%;
            height: 2rem;
            @include flex(row, flex-start, center);
            padding-left: 0.5rem;
            background-color: $gray-13;
            border: 0.13rem solid $gray-11;
            border-top: 0.13rem solid $gray-12;
            border-left: 0.13rem solid $gray-12;
          }

          .btn {
            width: 100%;
            background-color: $gray-13;
            color: $gray-5;
            font-weight: 600;
            border: 0.1rem solid $gray-11;
            border-top: 0.1rem solid $gray-12;
            border-left: 0.1rem solid $gray-12;
            font-size: 0.8rem;
            height: 4rem;
            &:hover {
              background-color: $gray-14;
            }

            &.submit {
              background-color: $black;
              color: $gray-13;
              font-size: 0.9rem;
              height: 3rem;

              &:hover {
                color: $white;
              }
            }

            /* App.css or your main CSS file */
            .loading-spinner {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          select {
            width: 100%;
            padding: 0.5rem;
            border: 1px solid $gray-13;
            border-radius: 0.2rem;
            outline: none;
            font-size: 0.9rem;
            color: $gray-3;
            border-right: none;
            border-top: 0.12rem solid $gray-13;
            border-left: 0.12rem solid $gray-13;
          }

          .form-row-col {
            width: 40%;
            @include flex(column, flex-start, flex-start);

            .btn {
              width: 100%;
              background-color: $gray-13;
              color: $gray-5;
              font-weight: 600;
              border: 0.1rem solid $gray-11;
              border-top: 0.1rem solid $gray-12;
              border-left: 0.1rem solid $gray-12;
              font-size: 0.8rem;
              height: auto;

              &:hover {
                background-color: $gray-14;
              }
            }
          }

          .form-row-table {
            width: 60%;
            @include flex(column, flex-start, flex-start);

            .form-row-table-header {
              @include flex(row, flex-start, flex-start);
              width: 100%;

              .form-row-table-entity {
                @include flex(row, flex-start, center);
                background-color: $gray-14;
                border: 0.12rem solid $gray-11;
                border-top: 0.12rem solid $gray-12;
                border-left: 0.12rem solid $gray-11;
                padding-left: 0.5rem;
                height: 1.5rem;
                font-size: 0.8rem;
                font-weight: 400;
                color: $gray-1;
              }
            }

            .form-row-table-row {
              @include flex(row, flex-start, flex-start);
              width: 100%;

              .form-row-table-entity {
                @include flex(row, flex-start, center);
                border: 0.12rem solid $gray-12;
                border-top: 0.12rem solid $gray-13;
                border-left: 0.12rem solid $gray-13;
                padding-left: 0.5rem;
                height: 2rem;
                font-size: 0.8rem;
                font-weight: 300;
                color: $gray-1;

                select {
                  padding: 0;
                  height: 100%;
                }
              }
            }
          }

          .form-row-item {
            width: 100%;
            @include flex(column, flex-start, flex-start);
            border-right: 1px solid $gray-13;
            position: relative;

            label {
              font-size: 0.8rem;
              font-weight: 300;
              color: $gray-1;
              width: 100%;
              height: 1.5rem;
              padding-left: 0.5rem;
              @include flex(row, flex-start, center);
              background-color: $gray-14;
              border: 0.12rem solid $gray-12;
              border-top: 0.12rem solid $gray-13;
              border-left: 0.12rem solid $gray-13;
            }

            .react-datepicker-wrapper {
              width: 100%;
            }

            .react-datepicker__input-container {
              width: 100%;

              input {
                width: 100%;
              }
            }

            input {
              width: 100%;
              padding: 0.5rem;
              border: 1px solid $gray-13;
              border-radius: 0.2rem;
              outline: none;
              font-size: 0.9rem;
              color: $gray-3;
              border-right: none;
              border-top: 0.12rem solid $gray-13;
              border-left: 0.12rem solid $gray-13;
            }

            textarea {
              width: 100%;
              padding: 0.5rem;
              border: 1px solid $gray-13;
              border-radius: 0.2rem;
              outline: none;
              font-size: 0.9rem;
              color: $gray-3;
              min-height: 8rem;
              border-right: none;
              border-top: 0.12rem solid $gray-13;
              border-left: 0.12rem solid $gray-13;
            }

            &.floor-plan {
              height: 20rem;
              align-items: center;
              justify-content: center;

              input {
                height: 100%;
              }

              .form-row-images {
                width: 100%;
                height: 20rem;

                .form-row-image {
                  width: 100%;
                  height: 20rem;

                  img {
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
