@import "../App.scss";

.login-page {
  position: relative;
  padding-top: 5rem;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-1;
    z-index: 2;
    opacity: 0.9;
  }

  .background-image {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    object-fit: cover;
  }

  .login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    padding: 1.5rem 2rem;
    background-color: $gray-1;
    border: 1px solid $gray-3;
    border-radius: 0.3rem;
    z-index: 3;
    @include flex(column, center, center);

    .logo {
      background-color: $white;
      border-radius: 0.3rem;
      width: 43%;
      height: 9rem;
      margin: 1rem 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h3 {
      font-size: 2rem;
      font-weight: 600;
      color: $white;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;
      line-height: 1.3;
    }

    p {
      font-size: 0.85rem;
      font-weight: 300;
      color: $gray-13;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;
      line-height: 1.3;
    }

    form {
      width: 100%;
      @include flex(column, center, center);
      gap: 0.6rem;

      input {
        width: 100%;
        height: 2.5rem;
        background-color: $gray-1;
        border: 1px solid $gray-3;
        border-radius: 0.3rem;
        padding: 0 1rem;
        font-size: 0.95rem;
        color: $white;
        outline: none;

        &:focus {
          border-color: $gray-7;
        }
      }

      .btn {
        margin-top: 0.3rem;
        width: 100%;
        height: 2.5rem;

        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .forgot-password {
      padding-top: 1rem;
      @include flex(row, center, center);

      a {
        font-size: 0.85rem;
        font-weight: 300;
        color: $gray-14;
      }
    }
  }
}
