@import "../App.scss";

header {
  width: 100%;
  @include flex(column, center, center);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  border-bottom: 1px solid $gray-3;

  .top-bar {
    @include flex(row, space-between, center);
    width: 100%;
    height: 2.2rem;
    background-color: $gray-1;
    border-bottom: 1px solid $gray-2;
    padding: 0 2rem;
    z-index: 3;

    .dropdowns {
      @include flex(row, center, center);
      height: 100%;
      transform: translateX(-0.75rem);
      .dropdown {
        position: relative;
        @include flex(row, center, center);

        .dropdown-button {
          background-color: transparent;
          text-decoration: none;
          border: none;
          outline: none;
          color: $gray-12;
          font-size: 0.78rem;
          font-weight: 300;
          cursor: pointer;
          padding: 0.34rem 0.7rem;
          display: block;
          border-radius: 0.2rem;
          z-index: 2;

          &:hover {
            background-color: $gray-2;
          }
        }

        .dropdown-content {
          @include flex(column, flex-start, flex-start);
          position: absolute;
          background-color: $white;
          min-width: 10rem;
          box-shadow: 0 0.5rem 1rem rgba($black, 0.1);
          z-index: 1;
          border: 1px solid $gray-13;
          top: 2.3rem;
          left: 0;
          border-radius: 0.2rem;
          display: none;
          z-index: 2;

          &.active {
            display: flex;
          }

          a,
          button {
            color: $gray-3;
            padding: 0.5rem 1rem;
            text-decoration: none;
            border: none;
            outline: none;
            display: block;
            font-size: 0.8rem;
            font-weight: 300;
            border-bottom: 1px solid $gray-13;
            width: 100%;
            text-align: left;
            color: $gray-3;
            font-weight: 400;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background-color: $gray-14;
            }
          }
        }

        .close {
          position: absolute;
          top: 2.5rem;
          left: -10vw;
          width: 130vw;
          height: 130vh;
          display: none;
          z-index: 0;

          &.active {
            display: block;
          }
        }
      }
    }

    .time-and-made-by {
      @include flex(row, center, center);
      gap: 1rem;
      height: 100%;

      .time {
        color: $gray-13;
        font-size: 0.78rem;
        font-weight: 300;
      }

      .made-by {
        @include flex(row, center, center);
        height: 100%;
        gap: 0.5rem;
        padding-left: 1rem;
        border-left: 1px solid $gray-2;
        p {
          color: $gray-13;
          font-size: 0.78rem;
          font-weight: 300;
        }

        a {
          color: $gray-13;
          font-size: 0.8rem;
          font-weight: 500;

          &:hover {
            color: $gray-15;
          }
        }
      }
    }
  }

  .header {
    width: 100%;
    @include flex(row, space-between, center);
    background-color: $gray-1;
    height: 4.7rem;

    .header-left {
      @include flex(row, space-between, center);
      height: 100%;
      padding: 0 2rem;
      position: relative;
      gap: 3rem;
      .header-left-page-info {
        @include flex(row-reverse, flex-end, flex-end);
        height: 100%;
        gap: 1rem;
        padding: 1rem 0;

        h5 {
          color: $gray-13;
          font-size: 1rem;
          font-weight: 300;
          margin-bottom: 0.3rem;
        }

        h3 {
          color: $white;
          font-size: 2.5rem;
          font-weight: 500;
        }
      }
      .search {
        @include flex(row, center, center);
        gap: 1rem;
        background-color: $gray-3;
        border-radius: 0.1rem;
        border: 1px solid $gray-4;
        height: 2.3rem;
        padding-right: 1rem;

        input {
          background-color: transparent;
          border: none;
          outline: none;
          color: $gray-13;
          font-size: 0.95rem;
          font-weight: 300;
          padding: 0 1.3rem;
          width: calc(100% - 1.2rem);
          height: 90%;
          border-right: 1px solid $gray-4;
          width: 18rem;

          &:focus {
            width: 22rem;
          }

          &::placeholder {
            color: $gray-11;
            font-size: 0.95rem;
            font-weight: 300;
          }
        }

        svg {
          color: $gray-13;
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    .header-right {
      padding: 1rem 2rem;
      @include flex(column, flex-end, flex-end);
      height: 100%;

      .user {
        @include flex(row, center, center);
        gap: 1rem;
        cursor: pointer;
        position: relative;

        .user-info {
          @include flex(row, center, center);
          gap: 0.7rem;

          .user-image {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            overflow: hidden;
            padding: 0.4rem;
            background-color: $gray-3;
            @include flex(row, center, center);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            svg {
              width: 100%;
              height: 100%;
              color: $gray-14;
            }
          }

          .user-name {
            color: $gray-14;
            font-size: 0.85rem;
            font-weight: 300;
          }

          .user-role {
            color: $white;
            font-size: 0.8rem;
            font-weight: 300;
            background-color: $gray-3;
            padding: 0.35rem 0.8rem;
            border-radius: 0.2rem;
          }
        }
      }
    }
  }

  nav {
    position: relative;
    @include flex(row, flex-start, flex-start);
    height: 2.9rem;
    width: 100%;
    background-color: $gray-1;
    padding-left: 2rem;
    border-top: 1px solid $gray-2;

    .border-bottom-line-active {
      position: absolute;
      bottom: -0.14rem;
      left: 0;
      width: 100%;
      height: 0.14rem;
      background-color: $white;
      width: 5rem;
      transition: all 0.3s ease;
    }

    .rectangle-background-active {
      position: absolute;
      bottom: 0.45rem;
      left: 0;
      width: 100%;
      height: 2rem;
      background-color: $gray-2;
      width: 5rem;
      border-radius: 0.3rem;
      transition: all 0.3s ease;
    }

    a {
      color: $gray-13;
      font-size: 0.93rem;
      font-weight: 300;
      text-decoration: none;
      padding: 0.9rem 1rem;
      margin-right: 1rem;
      border-bottom: 1px solid transparent;
      z-index: 2;

      &.active {
        color: $white;
      }
    }
  }
}

@keyframes dropDown {
  from {
    opacity: 0;
    transform: translateY(0rem);
  }
  to {
    opacity: 1;
    transform: translateY(0.5rem);
  }
}
