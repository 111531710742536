@import "../App.scss";

.home {
  @include flex(column, center, center);
  width: 100%;

  .home-nav {
    width: 100%;
    height: 100%;
    @include flex(row, center, center);

    .home-nav-item {
      width: 50%;
      height: 100%;
      position: relative;
      @include flex(column, center, flex-start);
      gap: 2rem;
      padding: 0 3rem;
      img {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.7);
      }

      h2 {
        z-index: 1;
        color: $white;
        font-size: 5rem;
        font-weight: 500;
      }

      p {
        z-index: 1;
        color: $gray-14;
        font-size: 1rem;
        font-weight: 400;
      }

      .btn {
        z-index: 1;
      }
    }
  }
}
